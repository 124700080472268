/**
 * Dapp
 * @author Lopes
 */
import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {Category} from '@/model/resource/Category'
import {NntTag} from '@/model/resource/NntTag'
import {Token} from '@/model/resource/Token'
import {Wallet} from '@/model/resource/Wallet'
import {DappCollection} from '@/model/collection/DappCollection'
import {DappDailyCache} from '@/model/resource/DappDailyCache'
import {DappSocial} from '@/model/resource/DappSocial'
import {Contract} from '@/model/resource/Contract'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'
import {PrivateCategory} from '@/model/resource/PrivateCategory'

export class Dapp implements IResource {
  idDappPk: number = 0

  @ResponseSerialize(Category)
  dappCategory: Category[] = []

  @ResponseSerialize(NntTag)
  dappNntTag: NntTag[] = []

  @ResponseSerialize(Token)
  dappToken: Token[] = []

  @ResponseSerialize(Wallet)
  dappWallet: Wallet[] = []

  @ResponseSerialize(DappSocial)
  dappSocial: DappSocial[] | null = null

  @ResponseSerialize(Contract)
  contract: Contract[] | null = null

  @ResponseSerialize(DappDailyCache)
  dappDailyCache: DappDailyCache | null = null

  @ResponseSerialize(BlockchainVersion)
  blockchainVersion: BlockchainVersion | null = null

  @ResponseSerialize(PrivateCategory)
  privateCategory: PrivateCategory | null = null

  imageUrl: string | null = null
  private: boolean = false
  categoryNamesCache: string | null = null
  title: string | null = null
  shortDescription: string | null = null
  websiteUrl: string | null = null
  companyName: string | null = null
  active: boolean | null = null
  description: string | null = null
  slug: string | null = null
  detailed: boolean | null = null

  inActiveDevelopment: boolean | null = null

  isDapp: boolean | null = null

  wasHighlighted: boolean | null = null
  publishedDate: string | null = null

  get $id() {
    return this.idDappPk
  }

  set $id(val) {
    this.idDappPk = val
  }

  get $tag() {
    return String(this.title)
  }

  get categoryNames() {
    if (!this.categoryNamesCache) return []

    const names = this.categoryNamesCache.split(' ')
    return names.filter((item, i) => names.indexOf(item) == i).slice(0, 3)
  }

  get blockchainVersionTitle() {
    return this.blockchainVersion?.title ?? ''
  }

  get blockchainVersionFormattedColor() {
    return `background-color: ${this.blockchainVersion?.color}`
  }

  get blockchainTagName() {
    return (
      this.blockchainVersion?.tagName ?? this.blockchainVersion?.title ?? ''
    )
  }

  /**
   * Lists the instances of Dapp
   */
  static async listDapp(params: any) {
    return await Request.get(`/client/dapp`, {params})
      .name('listDapp')
      .as(DappCollection)
      .getData()
  }

  /**
   * Lists the instances of Dapp to export as a file
   */
  static async listExportDapp(params: any) {
    return await Request.get(`/client/dapp/export`, {params})
      .name('listExportDapp')
      .as(DappCollection)
      .getData()
  }

  /**
   * Persists a new instance of Dapp. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistDapp() {
    return await Request.post(`/client/dapp`, this)
      .name('persistDapp')
      .asNumber()
      .getData()
  }

  /**
   * Gets contracts of a given ID of Dapp
   */
  async getContractsDapp() {
    const contracts = await Request.get(
      `/client/dapp/${this.slug ?? ''}/contracts`
    )
      .name('getContractsDapp')
      .asArrayOf(Contract)
      .getData()

    this.contract = contracts

    return contracts
  }

  /**
   * Gets an instance of a given ID or slug of Dapp
   */
  async getDapp(id: number | string) {
    return await Request.get(`/client/dapp/${id}`, {
      params: {detailed: this.detailed},
    })
      .name('getDapp')
      .as(this)
      .getData()
  }

  /**
   * Deletes an instance of a given ID of Dapp
   */
  async removeDapp() {
    return await Request.delete(`/client/dapp/${this.$id}`)
      .name('removeDapp')
      .asNumber()
      .getData()
  }
}
