/**
 * Contract Daily Cache
 * @author beckerin
 */
import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {Contract} from '@/model/resource/Contract'

export class ContractDailyCache implements IResource {
  idContractDailyCachePk: number = 0

  @ResponseSerialize(Contract)
  contract: Contract | null = null

  transactionCount: number | null = null
  transactionCountCurrentWeek: number | null = null
  transactionCountLastWeek: number | null = null
  score: number | null = null
  date: string | null = null

  get $id() {
    return this.idContractDailyCachePk
  }

  set $id(val) {
    if (!this.contract) {
      this.contract = new Contract()
    }
    this.contract.idContractPk = val
  }

  get $tag() {
    if (!this.contract) return ''
    return `${this.contract.title} ${
      this.contract.hash ? ' - ' + this.contract.hash : ''
    }`
  }

  get percentageGrow() {
    return (
      (((this.transactionCountCurrentWeek ?? 0) -
        (this.transactionCountLastWeek ?? 0)) /
        Math.max(1, this.transactionCountLastWeek ?? 0)) *
      100
    )
  }

  /**
   * Lists the instances of ContractDailyCache
   */
  static async listContractDailyCache(params: any | null = null) {
    return await Request.get(`/client/contract-daily-cache/`, {
      params,
    })
      .name('getContractDailyCache')
      .asArrayOf(this)
      .getData()
  }
}
