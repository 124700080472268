/**
 * DappDailyCache
 * @author Simpli CLI generator
 */
import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {Dapp} from '@/model/resource/Dapp'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {DappDailyCacheCollection} from '@/model/collection/DappDailyCacheCollection'

export class DappDailyCache implements IResource {
  @ResponseSerialize(Dapp)
  dapp: Dapp | null = null

  score: number | null = null
  transactionCountToday: number | null = null
  transactionCountCurrentWeek: number | null = null
  transactionCountLastWeek: number | null = null
  date: string | null = null

  get transactionCount() {
    return this.transactionCountToday
  }

  get $id() {
    /* TODO: define the ID */
    return 0
  }

  set $id(val) {
    /* TODO: define the ID */
  }

  get $tag() {
    return String(this.$id)
  }

  get idDappFk() {
    if (!this.dapp) return 0
    return this.dapp.$id
  }

  set idDappFk(val) {
    if (!this.dapp) this.dapp = new Dapp()
    this.dapp.$id = val
  }

  get transactionCountPercentage(): number {
    return (
      (((this.transactionCountCurrentWeek ?? 0) -
        (this.transactionCountLastWeek ?? 0)) /
        Math.max(1, this.transactionCountLastWeek ?? 1)) *
      100
    )
  }

  /**
   * Lists the instances of DappDailyCache
   */
  static async listDappDailyCache(params: any) {
    return await Request.get(`/client/dapp-daily-cache`, {params})
      .name('listDappDailyCache')
      .as(DappDailyCacheCollection)
      .getData()
  }

  /**
   * Resync Transaction data from dora
   */
  static async resyncTxData() {
    return await Request.post(
      `/client/dapp-daily-cache/job/repopulate-dapps-statistics`
    )
      .name('resyncTxData')
      .asVoid()
      .getData()
  }

  /**
   * Gets a instance of a given ID of DappDailyCache
   */
  async getDappDailyCache(id: number) {
    return await Request.get(`/client/dapp-daily-cache/${id}`)
      .name('getDappDailyCache')
      .as(this)
      .getData()
  }

  /**
   * Persists a new instance of DappDailyCache. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistDappDailyCache() {
    return await Request.post(`/client/dapp-daily-cache`, this)
      .name('persistDappDailyCache')
      .asNumber()
      .getData()
  }
}
