/**
 * DappDailyCacheCollection
 *
 * @author Simpli CLI generator
 */
import {HttpExclude, Request, RequestExpose} from '@simpli/serialized-request'
import {PageCollection} from '@simpli/resource-collection'
import {DappDailyCache} from '@/model/resource/DappDailyCache'
import {DappCollection} from '@/model/collection/DappCollection'

@HttpExclude()
export class DappDailyCacheCollection extends PageCollection<DappDailyCache> {
  constructor() {
    super(DappDailyCache)
  }

  resource?: IDappDailyCacheCollectionResourcesHolder

  @RequestExpose() idDappFk: number | null = null
  @RequestExpose() startDate: string | null = null
  @RequestExpose() endDate: string | null = null
  @RequestExpose() minScore: number | null = null
  @RequestExpose() maxScore: number | null = null
  @RequestExpose() minTransactionCountDiff: number | null = null
  @RequestExpose() maxTransactionCountDiff: number | null = null
  @RequestExpose() minTransactionCount: number | null = null
  @RequestExpose() maxTransactionCount: number | null = null

  queryAsPage() {
    return this.listDappDailyCache()
  }

  async listDappDailyCache() {
    return await Request.get(`/client/dapp-daily-cache`, {params: this.params})
      .name('listDappDailyCache')
      .as(this)
      .getResponse()
  }

  async listExportDappDailyCache() {
    return await Request.get(`/client/dapp-daily-cache/export`, {
      params: this.params,
    })
      .name('listExportDappDailyCache')
      .as(this)
      .getResponse()
  }
}

export interface IDappDailyCacheCollectionResourcesHolder {
  collectionDapp: DappCollection
}
