/**
 * Contract
 * @author Simpli CLI generator
 */
import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {Dapp} from '@/model/resource/Dapp'
import {ContractCollection} from '@/model/collection/ContractCollection'
import {ContractDailyCache} from '@/model/resource/ContractDailyCache'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'
import {ContractNep17} from '@/model/resource/ContractNep17'
import {PriceHistory} from '@/model/resource/PriceHistory'

/* TODO: review generated class */
export class Contract implements IResource {
  idContractPk: number = 0

  imageUrl: string | null = null

  @ResponseSerialize(ContractDailyCache)
  contractDailyCache: ContractDailyCache[] = []

  @ResponseSerialize(BlockchainVersion)
  blockchainVersion: BlockchainVersion | null = null

  @ResponseSerialize(ContractNep17)
  contractNep17: ContractNep17 | null = null

  @ResponseSerialize(Dapp)
  dapp: Dapp | null = null

  rank: number | null = null

  title: string | null = null
  hash: string | null = null
  hashes: string[] | null = null
  deploymentDate: string | null = null
  active: boolean | null = null

  get order() {
    if (!this.contractNep17) return 0

    return this.contractNep17.order
  }

  set order(val) {
    if (!this.contractNep17) return

    this.contractNep17.order = val
  }

  get $id() {
    return this.idContractPk
  }
  set $id(val) {
    this.idContractPk = val
  }
  get $tag() {
    return `${this.title} ${this.hash ? ' - ' + this.hash : ''}`
  }

  get idDappFk() {
    if (!this.dapp) return 0
    return this.dapp.$id
  }
  set idDappFk(val) {
    if (!this.dapp) this.dapp = new Dapp()
    this.dapp.$id = val
  }

  /**
   * Gets a instance of a given ID of Contract
   */
  async getContract(id: number) {
    return await Request.get(`/client/contract/${id}`)
      .name('getContract')
      .as(this)
      .getData()
  }

  /**
   * Persists a new instance of Contract. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistContract() {
    return await Request.post(`/client/contract`, this)
      .name('persistContract')
      .asNumber()
      .getData()
  }

  /**
   * Deletes a instance of a given ID of Wallet
   */
  async removeContract(idDapp: number, idContract: number) {
    return await Request.delete(`/client/contract/${idDapp}/${idContract}`)
      .name('removeContract')
      .asNumber()
      .getData()
  }
  getContractDailyCache(index: number = 0) {
    return this.contractDailyCache[index]
  }

  async softDeleteOrRecoverContract(idContract: number, active: boolean) {
    return await Request.post(`/client/contract/active/${idContract}/${active}`)
      .name('softDeleteOrRecoverContract')
      .asNumber()
      .getData()
  }

  static async fetchContracts() {
    return await Request.post(`/client/contract/job/fetch-new-contracts`)
      .name('fetchContracts')
      .asVoid()
      .getResponse()
  }
}
